import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Avatar",
  "type": "Other"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Avatar`}</strong>{` component used to render a user circle with an avatar and by configuration
the user's name and role.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Avatar
  imageUrl="https://bit.ly/2J3QSe3"
  name="Ivan Drinchev"
  title="Manager"
/>
`}</code></pre>
    <h2>{`Image vs Background color`}</h2>
    <p>{`The `}<strong parentName="p">{`Avatar`}</strong>{` can be rendered with an image or a background color, based on if the user
has an image already uploaded to his / her profile.`}</p>
    <h3>{`Image`}</h3>
    <p>{`An image is rendered in the profile section. Use this if there is a profile image set
by the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Avatar
  imageUrl="https://bit.ly/2J3QSe3"
/>
`}</code></pre>
    <h3>{`Background color and a placeholder`}</h3>
    <p>{`When there is no `}<inlineCode parentName="p">{`imageUrl`}</inlineCode>{` set, there is a possibility of using a `}<inlineCode parentName="p">{`placeholder`}</inlineCode>{`, which
renders in the middle of the avatar's profile circle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Avatar
  backgroundColor="#FF6600"
  placeholder="ID"
/>
`}</code></pre>
    <h2>{`Name and title`}</h2>
    <p>{`Avatar accepts a `}<inlineCode parentName="p">{`name`}</inlineCode>{` and `}<inlineCode parentName="p">{`title`}</inlineCode>{` to display next to the profile circle. Its purpose
is to display the user's real name and the role in the organisation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Avatar
  imageUrl="https://bit.ly/2J3QSe3"
  name="Ivan Drinchev"
  title="Manager"
/>
`}</code></pre>
    <h2>{`Menu`}</h2>
    <p><strong parentName="p">{`Avatar`}</strong>{` can include a dropdown menu, which is available through providing the
`}<inlineCode parentName="p">{`menu`}</inlineCode>{` prop. It goes great with the `}<a parentName="p" {...{
        "href": "/navigation/menu/"
      }}><strong parentName="a">{`Menu`}</strong></a>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Avatar
    imageUrl="https://bit.ly/2J3QSe3"
    name="Ivan Drinchev"
    title="Manager"
    menu={
        <Menu>
           <MenuItem leftIcon={ <Icons.BiCog /> }>Settings</MenuItem>
           <MenuItem leftIcon={ <Icons.BiLogOut /> }>Sign Out</MenuItem>
        </Menu>
    }
/>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The real name of the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The role of the user in the organisation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`imageUrl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user avatar displayed in the circle on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color of the profile provided in case there is no image`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The initials displayed in the circle, when there is a background color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`menu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The dropdown menu rendered as a content`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      